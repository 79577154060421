<template>
  <component
    :is="asyncDialog"
    v-model:visible="websiteConfig.dialogs.forms.state"
    append-to="#teleports"
    contentScrollable
    css-class="o-dialog-forms"
    @update:visible="dialogMounted"
  >
    <template v-if="websiteConfig.dialogs.forms.requiredForm" #header>
      <h2 class="o-dialog-form__title">
        {{ websiteConfig.dialogs.forms.requiredForm.title }}
      </h2>
      <h3 class="o-dialog-form__subtitle">
        {{ websiteConfig.dialogs.forms.requiredForm.subtitle }}
      </h3>
    </template>

    <template #content>
      <component v-if="requiredForm" :is="requiredForm" />
    </template>

    <template v-if="websiteConfig.dialogs.forms.requiredForm" #footer>
      <Button
        aria-label="Navigation"
        css-class="button-navigation"
        theme="dark"
        @click="closeFormDialog"
      >
        <i class="icon-main-close"></i>
      </Button>
    </template>
  </component>
</template>

<script lang="ts" setup>
// Components
import Loader from '@ice-products-ui/vue-library/Loader';
import Button from '@ice-products-ui/vue-library/Button';

// Types
import type { TWebsiteConfig } from '~/typings/types/website-config.types';

// Composables
import useDialogs from '~/composables/organisms/useDialogs';

const asyncDialog = defineAsyncComponent(
  async () => await import('@ice-products-ui/vue-library/Dialog'),
);

const websiteConfig = useState<TWebsiteConfig>('config');

const { closeFormDialog } = useDialogs();

const requiredForm = shallowRef();

function dialogMounted() {
  requiredForm.value = defineAsyncComponent({
    loader: () =>
      import(
        `~/components/M/Forms/${websiteConfig.value.dialogs.forms.requiredForm?.component}/${websiteConfig.value.dialogs.forms.requiredForm?.component}.vue`
      ),

    suspensible: false,
    loadingComponent: Loader,
  });
}
</script>
